import { APICategory } from "@merge-api/merge-javascript-shared";
import { OpenAPIV3 } from "openapi-types";

export enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
}

// Temporary "model" for the rendering information we need for categories
export interface CategoryInfo {
  featherIcon: string;
  displayName: string;
  categoryPreview: string;
  fullDisplayName: string;
  pagePath: string;
  publicationStatus: CategoryPublicationStatus;
  footerTitle: string;
  descriptiveName: string;
  docsLink: string;
  abbreviation: string;
}

export enum CategoryPublicationStatus {
  inactive = "inactive",
  beta = "beta",
  active = "active",
}

export interface OrganizationConfigStatusField {
  completed: boolean;
  description: string;
  field_name: string;
}

export interface Integration {
  id: string;
  name: string;
  abbreviated_name?: string;
  image: string;
  square_image?: string;
  slug?: string;
  coverage: string;
  base_api_url: string;
  modified_at: string;
  active: boolean;
  categories: Array<string>;
  is_enabled?: boolean;
  organization_config_status: OrganizationConfigStatusField[];
  pipeline_states: Partial<Record<HTTPMethod, [string, PipelineStateType][]>>;
  isAutoWebhookSetupEnabled?: boolean;
  isManualWebhookSetupEnabled?: boolean;
  webhookSetupGuideURL?: string;
  category_beta_status: Partial<Record<APICategory, boolean>>;
}

export type IntegrationWithSlug = Omit<Integration, "categories"> & {
  slug: string;
  categories: Array<APICategory>;
};

export interface RouterLocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  key?: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  state: Record<string, unknown> | null;
}
export interface DropDownOption {
  value: string;
  label: string;
}
export interface MappedCommonModel {
  model_name: string;
  mapped_fields: Array<string>;
}

export interface Endpoint {
  common_models: Array<MappedCommonModel>;
  path: string;
  method: HTTPMethod;
  category: string;
}

export interface EndpointMapping {
  endpoints: Array<Endpoint>;
  integration: IntegrationWithSlug;
}

export enum PipelineStateType {
  COMING_SOON = "COMING_SOON",
  NOT_SUPPORTED = "NOT_SUPPORTED",
  HIDDEN = "HIDDEN",
}

export enum APIEndpointTabKey {
  SYNC_FREQUENCIES = "sync-frequencies",
  PROVIDER_INTERACTIONS = "provider-interactions",
}

export enum FilePickerTabKey {
  FILE = "files",
  FOLDER = "folders",
  DRIVE = "drives",
}

type EndpointModelInfo = {
  modelName: string;
  pluralName: string;
  dropdownText: string;
  endpointPath: string;
  exampleProviderName: string;
  exampleProviderLink: string;
};

export const CategoryPrimaryModelInfo: Record<APICategory, EndpointModelInfo> = {
  [APICategory.hris]: {
    modelName: "Employee",
    pluralName: "Employees",
    dropdownText: "Employees (HRIS)",
    endpointPath: "hris/v1/employees",
    exampleProviderName: "BambooHR",
    exampleProviderLink: "https://www.bamboohr.com/signup/",
  },
  [APICategory.ats]: {
    modelName: "Candidate",
    pluralName: "Candidates",
    dropdownText: "Candidates (ATS)",
    endpointPath: "ats/v1/candidates",
    exampleProviderName: "Teamtailor",
    exampleProviderLink: "https://www.teamtailor.com",
  },
  [APICategory.accounting]: {
    modelName: "Journal Entry",
    pluralName: "Journal Entries",
    dropdownText: "Journal Entries (Accounting)",
    endpointPath: "accounting/v1/journal-entries",
    exampleProviderName: "QuickBooks",
    exampleProviderLink: "https://www.quickbooks.intuit.com",
  },
  [APICategory.ticketing]: {
    modelName: "Ticket",
    pluralName: "Tickets",
    dropdownText: "Tickets (Ticketing)",
    endpointPath: "ticketing/v1/tickets",
    exampleProviderName: "Jira",
    exampleProviderLink: "https://www.atlassian.com/software/jira",
  },
  [APICategory.crm]: {
    modelName: "Account",
    pluralName: "Accounts",
    dropdownText: "Accounts (CRM)",
    endpointPath: "crm/v1/accounts",
    exampleProviderName: "HubSpot",
    exampleProviderLink: "https://www.hubspot.com",
  },
  [APICategory.mktg]: {
    modelName: "Automation",
    pluralName: "Automations",
    dropdownText: "Automations (MKTG)",
    endpointPath: "mktg/v1/automations",
    exampleProviderName: "HubSpot Marketing Hub",
    exampleProviderLink: "https://www.hubspot.com",
  },
  [APICategory.filestorage]: {
    modelName: "File",
    pluralName: "Files",
    dropdownText: "Files (FileStorage)",
    endpointPath: "filestorage/v1/files",
    exampleProviderName: "Google Drive",
    exampleProviderLink: "https://drive.google.com",
  },
};

export type EndpointAvailability =
  | "ENDPOINT_AVAILABILITY_PUBLIC"
  | "ENDPOINT_AVAILABILITY_CLOSED_BETA"
  | "ENDPOINT_AVAILABILITY_PUBLIC_BETA"
  | "ENDPOINT_AVAILABILITY_DEPRECATED";

type MergeSchemaExtensions = {
  "x-endpoint-availability": EndpointAvailability;
};

export type MergeOperationObject = OpenAPIV3.OperationObject<MergeSchemaExtensions>;
export type MergePathItemObject = OpenAPIV3.PathItemObject<MergeSchemaExtensions>;
